// src/pages/About.tsx

import React from 'react';


const About: React.FC = () => {
    return (
        <>
            <div style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6", margin: "0 auto", maxWidth: "800px", padding: "20px" }}>
                <h1 style={{ textAlign: "center", color: "#FF5722" }}>About Draw Detective</h1>
                <p>
                    Welcome to <strong>Draw Detective</strong>, the ultimate free-to-play <strong>online multiplayer Pictionary-style game</strong> that brings friends, families, and communities together. Whether you're a budding artist or a word-guessing wizard, Draw Detective is your go-to destination for endless fun, creativity, and competition.
                </p>

                <h2 style={{ color: "#FF5722" }}>Why Choose Draw Detective?</h2>
                <p>
                    Looking for an exciting <strong>alternative to Skribbl.io</strong>? Draw Detective is designed to provide a seamless gaming experience with:
                </p>
                <ul>
                    <li><strong>Free and multiplayer gameplay:</strong> Play with friends or connect with players worldwide.</li>
                    <li><strong>Intuitive drawing tools:</strong> Easy-to-use canvas for players of all skill levels.</li>
                    <li><strong>Guess-and-draw mechanics:</strong> One player draws a word while others compete to guess it first.</li>
                    <li><strong>Engaging themes and modes:</strong> Enjoy a variety of game modes to keep things fresh and fun.</li>
                </ul>

                <h2 style={{ color: "#FF5722" }}>How to Play</h2>
                <ol>
                    <li>Join or create a game room.</li>
                    <li>One player becomes the artist and selects a word to draw.</li>
                    <li>Other players guess the word in real-time via chat.</li>
                    <li>Earn points for guessing quickly or for being the best artist!</li>
                </ol>

                <h2 style={{ color: "#FF5722" }}>Key Features</h2>
                <ul>
                    <li><strong>Free Pictionary game online:</strong> No downloads or installations required.</li>
                    <li><strong>Cross-device compatibility:</strong> Play on desktops, tablets, or mobile devices.</li>
                    <li><strong>Interactive social gaming:</strong> Chat with players while drawing and guessing.</li>
                    <li><strong>Customizable game settings:</strong> Adjust rounds, time limits, and more to fit your group’s preferences.</li>
                </ul>

                <h2 style={{ color: "#FF5722" }}>Perfect for Any Occasion</h2>
                <p>
                    Whether you're hosting a virtual party, looking for a fun activity during a video call, or simply unwinding after a long day, Draw Detective is the perfect <strong>Pictionary-style game</strong> to share laughter and creativity.
                </p>

                <h2 style={{ color: "#FF5722" }}>Join the Fun Today</h2>
                <p>
                    Start playing <strong>Draw Detective</strong> now and discover why it’s the most exciting <strong>alternative to Skribble</strong>! It's easy to play, completely free, and guaranteed to bring smiles and laughter to everyone involved.
                </p>
                <p>
                    Reconnect with friends and family, unleash your creativity, and become the ultimate word-guessing champion. <strong>Play Draw Detective for free today!</strong>
                </p>
            </div>
        </>
    );
};

const styles = {
    container: {
        padding: '20px',
        maxWidth: '800px',
        margin: '0 auto',
        textAlign: 'center' as const,
    },
    title: {
        fontSize: '2.5rem',
        color: '#333',
        marginBottom: '20px',
    },
    text: {
        fontSize: '1.2rem',
        color: '#555',
        lineHeight: '1.6',
        marginBottom: '15px',
    },
};

export default About;
