import DrawSegment from "../domain/entities/DrawSegment";
import Game from "../domain/entities/Game";
import Point from "../domain/entities/Point";
import Score from "../domain/entities/Score";
import { GameError, GameStatus, PlayerStatus, TurnStatus, WordCreator, WordDifficulty } from "./Constants";


export type ClientGameData = {
    uuid: string;
    status: GameStatus;
    settings?: ClientGameSetting;
    totalRounds: number;
    currentRound: number;
    turnData?: ClientTurnData;
    scores?: Scores;
    adminIds: string[];
}

export type ClientPlayerData = {
    uid: string;
    userName: string;
    avatarId: number;
    status: PlayerStatus;
}

export interface Scores {
    [key: string]: number;
}

export type ClientTurnData = {
    uid: string;
    word: string;
    wordSuggestions: string[];
    drawerId: string;
    status: TurnStatus;
    timer: number;
    totalDuration: number;
    scores: {
        [key: string]: Score[];
    };
};

export type TurnSummary = {
    scores?: Scores;
    word: string;
    everyoneGuessed: boolean;
}

export type WordData = {
    difficulty?: WordDifficulty,
    addedBy: WordCreator
}

export type ClientGameSetting = {
    minPlayers: number;
    maxPlayers: number;
    noOfRounds: number;
    drawingDuration: number;
    wordSelectionDuration: number;
    prepDuration: number;
    hintDisabled: boolean;
    hintDelay: number;
}

export type User = {
    userId: string;
    userName: string;
    avatarId: number;
}

export type ErrorHandlerCb = (errorEvent: string, errorMessage: GameError) => void;

export type Lobby = {
    uuid: string;
    label: string;
    roundsPlayed: number;
    totalRounds: number;
    noOfPlayers: number;
}

export type CreateRoomDTO = {
    roomId: string;
    game: Game;
}

export type FeedbackResponseDTO = {
    message:string,
    error?:string
}

export type FeedbackRequestDTO = {
    email?:string,
    firstName?:string,
    message:string,
    uuid:string
}

export interface HintRevealData {
    timestamp: number;
    hintWord: string
}

export interface CreateRoomRequestBody {
    userId: string;
    userName: string;
    avatarId: number;
    socketId: string;
}

// GIF Related:
export type GifMessageData = {
    message: string,
    senderId: string,
};
export enum GifFrameType {
    NORMAL = "normal",
    SUMMARY_OF_GUESSERS = "SUMMARY_OF_GUESSERS",
}
export type GifFrame = {
    type: GifFrameType,
    drawSegments?: DrawSegment[];
    messages?: GifMessageData[];
    wordSoFar?: string;
};
export type GifTurn = {
    turn: number,
    word: string,
    drawerId: string
    frames: GifFrame[],
};
export type GifRound = {
    round: number,
    turnsData: GifTurn[],
};
export type GifGameData = GifRound[];

export type idMapType = { [key: string]: { avatarId: string, name: string } };