import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/home/Home.tsx";
import RoomPage from "./components/Room.tsx";
import Page404 from "./components/Page404.tsx";
import { Layout } from "./components/Game.tsx";

import "./App.css";
import Leaderboard from "./components/LeaderBoard.tsx";
import FeedbackModal from "./components/FeedbackModal.tsx";
import RequireProfileData from "./components/RequireProfileData.tsx";
import { AppSocket } from "./socket.ts";
import { useEffect } from "react";
import About from "./components/About.tsx";

function App() {
  useEffect(() => {
    AppSocket.initSocket();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/room"
          element={
            <RequireProfileData>
              <RoomPage />
            </RequireProfileData>
          }
        />
        <Route path="/about" element={<About />} /> {/* Debug */}
        <Route path="/layout" element={<Layout />} /> {/* Debug */}
        <Route path="/my-page" element={<FeedbackModal />} /> {/* Debug */}
        {/* Debug */}
        <Route path="/ldb" element={<Leaderboard />} /> {/* Debug */}
        <Route path="*" element={<Page404 />} />
      </Routes>
    </Router>
  );
}

export default App;
